import { LAYOUT_CONTAINER_CLASS } from 'components/layout/App/Layout'
import { NextSeo } from 'next-seo'

import { withAppLayout } from 'src/components/layout/App/Layout'
import type { FreeportPage } from 'src/pages/_app'

const Page: FreeportPage = () => {
  return (
    <>
      <NextSeo title="404" noindex={true} />
      <section className={`${LAYOUT_CONTAINER_CLASS} h-full`}>
        <div className="h-full flex flex-col justify-center">
          <div className="md:flex md:flex-row md:justify-center md:items-center md:divide-x divide-gray-400 py-32">
            <h1 className="text-4xl font-bold my-4 md:pr-4">404</h1>
            <p className="text-xl leading-8 my-4 md:pl-4">
              We couldn&apos;t find the page you were looking for.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

Page.layout = withAppLayout

export default Page
